<template>
  <div class="about">
    <navbar></navbar>
    <div class="container">
      <div class="top">
        <h1>关于我们</h1>
        <h3>ABOUT US</h3>
      </div>
      <div class="center">
        <div class="title">
          <h1>PROFILE</h1>
          <h3 style="left: 30%">公司简介</h3>
        </div>
        <div class="text">
          <p>
            约码(山东)网络科技有限公司(简称:约码科技)，山东省互联网协会成员单位，创建于2021年，公司总部位于山东省济南市历城区耀泽商业中心，毗邻华山湖，风景优美。
          </p>
          <p>公司自创建以来一直为IT教育和发展做出贡献。</p>
          <p>
            约码科技为专业从事人工智能、大数据、IT服务、软件研发的互联网科技企业。为企业提供内部管理软件定制、电商销售软件定制、信息化培训在线应用服务、软件平台运维保障等软件业务。
          </p>
          <p>
            希望用高效的产品、专业的技术和完善的服务为客户提供先进、稳定的综合信息化服务。
          </p>
          <p>
            致力于为企业提供一站式软件技术解决方案，目标成为行业内一致认可的高新技术企业、软件企业、领先的软件产品服务供应商。
          </p>
        </div>
      </div>
      <div class="bottom">
        <div class="title">
          <h1>PHOTO</h1>
          <h3 style="left: 23%">公司相册</h3>
        </div>
        <div class="content">
          <el-carousel :interval="4000" type="card" height="450px">
            <el-carousel-item>
              <img src="/img/company1.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="/img/company2.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="/img/company.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="/img/company1.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="/img/company.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="/img/company2.jpg" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
export default {
  components: { Navbar, Foot },
  data() {
    return {};
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>
<style scoped>
.el-carousel {
  width: 1200px;
  margin: 0 auto;
}
.el-carousel img {
  height: 460px;
}

.about {
  overflow: hidden;
}
.bottom {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom > .content {
  width: 1800px;
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content > img {
  border-radius: 20px;
}
.content > img:nth-child(1) {
  width: 300px;
  height: 192px;
}
.content > img:nth-child(2) {
  width: 300px;
  height: 192px;
}
.content > img:nth-child(3) {
  width: 480px;
  height: 307px;
}
.content > img:nth-child(4) {
  width: 300px;
  height: 192px;
}
.content > img:nth-child(5) {
  width: 300px;
  height: 192px;
}
.center {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center > .text {
  width: 1200px;
  margin-top: 12px;
}
.center > .text > p {
  text-indent: 2em;
  letter-spacing: 1.8px;
  font-size: 20px;
  line-height: 35px;
}
.top {
  background-image: url("/img/aboutBG.png");
  width: 100%;
  height: 340px;
  max-width: 1920px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top > h1 {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: 5px;
  margin-bottom: 20px;
}

.title {
  position: relative;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}
@media screen and (max-width: 700px) {
  .container {
    width: 1200px;
    overflow: visible;
  }
  .about {
    overflow: visible;
  }
  .bottom > .content {
    width: 1200px;
  }
}
</style>