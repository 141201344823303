<template>
  <div class="index">
    <navbar></navbar>
    <swipe></swipe>
    <div v-show="windowShow" class="window">
      <img src="/img/x.png" alt="" @click="showWindow" />
      <div class="content" style="">
        <div class="top">
          <img src="../assets/img/smlogo.png" alt="" />
        </div>
        <h2>专业&#8226;务实&#8226;创新&#8226;高效</h2>
        <div style="text-decoration: underline">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <h2>咨询电话</h2>
        <h1>18310141089</h1>
        <img src="/img/QR2.png" alt="" />
        <h3>加我微信</h3>
      </div>
    </div>
    <div v-show="!windowShow" class="icon">
      <div @click="closeIcon">
        <img src="/img/ymkj.png" alt="" />
        <p>联系我们</p>
      </div>
      <div class="gotop" v-show="showIcon" @click="goon">
        <p>回到顶部</p>
      </div>
    </div>
    <div class="container">
      <!-- 公司简介 -->
      <div class="profile">
        <div class="title">
          <h1>PROFILE</h1>
          <h3>公司简介</h3>
        </div>
        <h4>山东省互联网协会成员单位</h4>
        <div class="swipe">
          <el-carousel
            :interval="4000"
            type="card"
            height="302px"
            indicator-position="none"
          >
            <el-carousel-item>
              <div class="swipe-item" @click="swipeClick">
                <img src="/img/company.jpg" alt="" />
                <div class="p">
                  <p>
                    拥有专业的开发团队、多位技术专家，丰富的项目经验，为企业提供专业的信息化软件，高效的系统解决方案，助力企业实现信息化运营，提高企业生产管理效率。
                  </p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swipe-item" @click="swipeClick">
                <img src="/img/company1.jpg" alt="" />
                <div class="p">
                  <p>
                    专业提供IT解决方案与服务，为企业提供从创立、发展到成熟的完整生命周期的软件产品和解决方案，于众多企业IT服务中积累了丰富的信息化经验。
                  </p>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swipe-item" @click="swipeClick">
                <img src="/img/company2.jpg" alt="" />
                <div class="p" style="bottom: -50">
                  <p>
                    专业从事人工智能、大数据、软件研发、技术咨询、IT教育产业的网络科技型、信息技术型企业，是行业内一致认可的高新技术软件企业
                  </p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 核心服务 -->
      <div class="coreservice">
        <div class="title">
          <h1>CORESERVICE</h1>
          <h3 style="left: 34%">核心服务</h3>
        </div>
        <div class="content">
          <div class="item">
            <img src="/img/icon-indexapp.png" alt="" />
            <div class="text">
              <h1>App开发</h1>
              <p>
                提供原生App开发、Web
                App开发及混合App开发服务。专业的技术团队，丰富的行业项目经验。
              </p>
            </div>
          </div>

          <div class="item">
            <img src="/img/icon-ruanjiankaifa.png" alt="" />
            <div class="text">
              <h1>软件开发</h1>
              <p>
                为政府、企业、事业单位及高校提供软件定制开发服务，如学习考试、电商零售、业务管理、智慧校园等系统。
              </p>
            </div>
          </div>
          <div class="item">
            <img src="/img/icon-xiaochengxu.png" alt="" />
            <div class="text">
              <h1>微信小程序</h1>
              <p>
                定制开发各种场景功能的小程序，结合微信公众号的交互能力，布局微信新生态，抢占用户。
              </p>
            </div>
          </div>
          <div class="item">
            <img src="/img/icon-wangzhanjianshe.png" alt="" />
            <div class="text">
              <h1>网站建设</h1>
              <p>
                致力于服务客户网站整体的品牌策略、商务策略、运行策略等，在各方面提供整体解决方案，实现品牌价值。
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 优势 -->
      <!-- <div class="advantage">
        <div class="title">
          <h1>ADVANTAGE</h1>
          <h3>我们的优势</h3>
        </div>
        <div class="content">
          <div class="icon">
            <div>
              <el-image
                src="/img/advantage1.png"
                style="height: 80px"
              ></el-image>
              <h2>大数据分析</h2>
            </div>
            <div>
              <el-image
                src="/img/advantage2.png"
                style="height: 80px"
              ></el-image>
              <h2>业务范围广</h2>
            </div>
            <div>
              <el-image
                src="/img/advantage5.png"
                style="height: 80px"
              ></el-image>
              <h2>专业流程</h2>
            </div>
            <div>
              <el-image
                src="/img/advantage3.png"
                style="height: 80px"
              ></el-image>
              <h2>专业团队</h2>
            </div>
            <div>
              <el-image
                src="/img/advantage6.png"
                style="height: 80px"
              ></el-image>
              <h2>用心服务</h2>
            </div>
          </div>
          <div class="card">
            <div
              class="item"
              @mouseover="selectStyle(i)"
              @mouseout="outStyle(i)"
              v-for="(item, i) in items"
              :key="i"
            >
              <div class="content">
                <el-image
                  :src="item.img"
                  style="height: 80px; width: 80px"
                  v-show="!show[i]"
                ></el-image>
                <el-image
                  :src="item.seleImg"
                  style="height: 80px; width: 80px"
                  v-show="show[i]"
                ></el-image>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 我们的优势 -->
      <div class="advantage">
        <div class="title">
          <h1>ADVANTAGE</h1>
          <h3 style="left: 31%">我们的优势</h3>
        </div>
        <div class="content">
          <div class="item">
            <img src="/img/ys1.png" alt="" />
            <h1>项目流程专业</h1>
          </div>
          <div class="item">
            <img src="/img/ys2.png" alt="" />
            <h1>需求分析严谨</h1>
          </div>
          <div class="item">
            <img
              src="/img/ys3.png"
              alt=""
              style="height: 63px; margin-top: 7px"
            />
            <h1>研发经验丰富</h1>
          </div>
          <div class="item">
            <img src="/img/ys4.png" alt="" />
            <h1>技术团队完备</h1>
          </div>
          <div class="item">
            <img src="/img/ys5.png" alt="" />
            <h1>产品设计精简</h1>
          </div>
          <div class="item">
            <img src="/img/ys6.png" alt="" />
            <h1>售后服务周到</h1>
          </div>
        </div>
      </div>
      <!-- 运维服务 -->
      <div class="service">
        <div class="title">
          <h1>SERVICE</h1>
          <h3 style="left: 27%">运维服务</h3>
        </div>
        <div class="content">
          <div style="background-image: url('/img/xinbg.png')" class="item">
            <img src="/img/xin.png" alt="" />
            <ul>
              <li><h1>标准服务</h1></li>
              <li>• 提供常规一年运维服务：故障处理、服务启停、6小时响应</li>
              <li>• 服务有效期内，提供总计不超出10天工作量的需求更新</li>
              <li>• 提供免费技术咨询、IT解决方案</li>
              <li>• 服务费用低，适应小量级需求用户</li>
            </ul>
          </div>
          <div class="item" style="background-image: url('/img/zuanshibg.png')">
            <img src="/img/zuanshi.png" alt="" />
            <ul>
              <li><h1>高级服务</h1></li>
              <li>• 提供一年常规运维服务：故障处理、服务启停、3小时响应</li>
              <li>• 服务有效期内，提供总计不超出30天工作量的需求更新</li>
              <li>• 每年不超出2次现场用户培训服务</li>
              <li>• 提供免费技术咨询、IT解决方案</li>
              <li>• 服务完善，适应更多需求量用户</li>
            </ul>
          </div>
          <div
            class="item"
            style="background-image: url('/img/huangguangbg.png')"
          >
            <img src="/img/huangguan.png" alt="" />
            <ul>
              <li><h1>尊享服务</h1></li>
              <li>
                •
                提供一年高级运维服务：故障处理、服务启停、远程指导、数据修复、1小时极速响应
              </li>
              <li>• 服务有效期内，提供总计不超出60天工作量的需求更新</li>
              <li>• 每年不超出5次现场用户培训服务</li>
              <li>• 提供免费技术咨询、IT解决方案</li>
              <li>• 畅享一对一保姆式尊享服务</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="cooperation">
        <div class="title">
          <h1>COOPERATION</h1>
          <h3 style="left: 35%">合作伙伴</h3>
        </div>
        <div class="show">
          <div class="left">
            <div
              @mouseover="hoveritem(i)"
              v-for="(item, i) in cooperations"
              :key="i"
              :class="{ sele: (i == x) & sele }"
            >
              <div></div>
              <h1>{{ item.title }}</h1>
            </div>
          </div>
          <div class="right">
            <img :src="cooperations[active].image" alt="" />
            <p>
              {{ cooperations[active].Introduction }}
            </p>
            <div class="line"></div>
            <div>
              <h1>合作内容</h1>
              <div></div>
              <p>{{ cooperations[active].subtitle }}</p>
            </div>
          </div>
        </div>

        <!-- <div class="card">
          <div class="item" v-for="(item, i) in cooperations" :key="i">
            <img :src="item.image" alt="" />
            <p>
              {{ item.Introduction }}
            </p>
            <div>
              <h1>合作内容</h1>
              <h2>{{ item.subtitle }}</h2>
            </div>
          </div>
        </div> -->
        <div class="subswipe">
          <div class="list">
            <div>
              <img src="/img/yongyoulogo.png" alt="" />
            </div>
            <div>
              <img src="/img/yiqifengtianlogo.png" alt="" />
            </div>
            <div>
              <img src="/img/gongshanglogo.png" alt="" />
            </div>
            <div>
              <img src="/img/zhaoshanglogo.png" alt="" />
            </div>
            <div>
              <img src="/img/nongyelogo.png" alt="" />
            </div>
            <div>
              <img src="/img/baomalogo.png" alt="" />
            </div>
            <div>
              <img src="/img/haixinlogo.png" alt="" />
            </div>
            <div>
              <img src="/img/youhanglogo.png" alt="" />
            </div>
            <div>
              <img src="/img/shenzhenranqi.png" alt="" />
            </div>
            <div>
              <img src="/img/yanghua.png" alt="" />
            </div>
            <div>
              <img src="/img/yzb.png" alt="" />
            </div>
            
          </div>
        </div>
      </div>
      <!-- 最新咨询 -->
      <div class="new">
        <div class="title">
          <h1>INFORMATION</h1>
          <h3 style="left: 36%">最新资讯</h3>
        </div>
        <div class="content">
          <div class="card">
            <el-carousel height="250px">
              <el-carousel-item>
                <img
                  src="/img/company.jpg"
                  alt=""
                  style="width: 320px; height: 250px"
                />
              </el-carousel-item>
              <el-carousel-item>
                <img
                  src="/img/company1.jpg"
                  alt=""
                  style="width: 320px; height: 250px"
                />
              </el-carousel-item>
              <el-carousel-item>
                <img
                  src="/img/company2.jpg"
                  alt=""
                  style="width: 320px; height: 250px"
                />
              </el-carousel-item>
            </el-carousel>
            <div class="text">
              <h1>约码(山东)网络科技有限公司</h1>
              <p>
                约码(山东)网络科技有限公司(简称:约码科技，山东省互联网协会成员单位)，为专业从事人工智能、数据计算、数据挖掘、数据分析、软件研发、IT教育产业咨询、电子产品商城等网络信息技术企业，致力于成为行业一致认可的高新技术企业。
                
              </p>
            </div>
          </div>
          <div class="list">
            <ul>
              <li>
                <div></div>
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247483707&idx=1&sn=308c3f727a5fe4ce572d1ef80ed10958&chksm=97be0c1ea0c9850824f0b7a0d76dfa268a5f5db3b9451ebec6049ec190f21e76ec6a22e7a389&scene=21#wechat_redirect"
                >
                  移动互联网的下半场
                </a>
                <p>2023-07-15</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247483747&idx=1&sn=195eb8d2e679c6aa8d8b2b328693abaa&chksm=97be0c46a0c985508104351addc691d6b9345acdc4acd38093033809979b061b06dd649d868e&scene=21#wechat_redirect"
                >
                  人类进入人工智能时代，迎来大爆发？
                </a>
                <p>2023-07-02</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247484243&idx=1&sn=482dba26f3aa09586d78312dd06e82c7&chksm=97be0e76a0c9876036de1bc3a456b319c23c189341691e7f9017e840354cbc5242ff16463f50&scene=21#wechat_redirect"
                  >互联网创业新思路，想不成功都难</a
                >
                <p>2023-06-28</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247484258&idx=1&sn=a5785e894c7df50191dd54f9b4b2cbea&chksm=97be0e47a0c98751fc4510353e87006dad6c76b572fbb4922a62380a0da9bc8e9af305e7bf7b&scene=21#wechat_redirect"
                >
                  行业 | 商业网站常见的盈利模式
                </a>
                <p>2023-06-17</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247484210&idx=1&sn=23157d0e8d07f06506056d9ccf2bca9b&chksm=97be0e17a0c987019dfa002f4b72a49b8bd2eb43ccf432ec7e25c3bbcb2d577e46bceace4c53&scene=21#wechat_redirect"
                  >企业架构中流行的技术</a
                >
                <p>2023-05-16</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247484417&idx=1&sn=2d5b388a60f8eb88fbf53bfbbafa6f9d&chksm=97be0924a0c980325e2ee6c02fa99f4d6528f49a1ef657af5bf245849888d960ba11aab84784&scene=21#wechat_redirect"
                >
                  架构 | 常见网站架构部署架构
                </a>
                <p>2023-05-06</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247484681&idx=1&sn=b313af37b9cd44651d3a1e1fc7d24992&chksm=97be082ca0c9813a71b0e29ea48eacc8e99cf9e86cd84fa610c11fb2aa10ff7521d71429535f&scene=21#wechat_redirect"
                >
                  区块算法 | 美国联邦政府采用的AES区块标准算法
                </a>
                <p>2023-04-19</p>
                <h1></h1>
              </li>
              <li>
                <div></div>
                <a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzIxMjg4NDU1NA==&mid=2247484674&idx=1&sn=4d17121429fefe3e0ee67af3c92ffa94&chksm=97be0827a0c9813161bc9871dad5733e01da0489579a5cf87306e703abc964d8e7e70045f3ae&scene=21#wechat_redirect"
                  >微服务 | 资深架构师解读如何使用微服务架构</a
                >
                <p>2023-04-16</p>
                <h1></h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
//引入导航 底部栏
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
import Swipe from "../components/Swipe.vue";
export default {
  components: { Navbar, Foot, Swipe },
  data() {
    return {
      windowShow: true,
      showIcon: false,
      // show: [false, false, false, false, false],
      // items: [
      //   {
      //     img: "/img/1.png",
      //     seleImg: "/img/1white.png",
      //     title: "立足行业,深刻理解客户需求！为客户提供卓越的服务！",
      //   },
      //   {
      //     img: "/img/2.png",
      //     seleImg: "/img/2white.png",
      //     title: "立足行业,深刻理解客户需求！为客户提供卓越的服务！",
      //   },
      //   {
      //     img: "/img/3.png",
      //     seleImg: "/img/3white.png",
      //     title: "立足行业,深刻理解客户需求！为客户提供卓越的服务！",
      //   },
      //   {
      //     img: "/img/4.png",
      //     seleImg: "/img/4white.png",
      //     title: "立足行业,深刻理解客户需求！为客户提供卓越的服务！",
      //   },
      //   {
      //     img: "/img/5.png",
      //     seleImg: "/img/5white.png",
      //     title: "立足行业,深刻理解客户需求！为客户提供卓越的服务！",
      //   },
      // ],
      cooperations: [
        {
          image: "/img/YY.png",
          Introduction:
            "用友科技成立于1988年，是全球领先的企业与公共组织云服务、软件提供商。目前在全球拥有230多个分支机构和8000多家生态伙伴，服务超过627万家企业客户。",
          title: "用友",
          subtitle: "ERP、供应链、资金财务、NC、U8、用友云 ",
          active: true,
        },
        {
          image: "/img/YH.png",
          Introduction:
            "优行教育(原杰弗教育)成立于2006年,是智力于快速记忆英语单词的英语培训加盟机构。目前在全国各地有加盟学校4000所，培训数百万人次。",
          title: "优行教育",
          subtitle: "App(智能英语)、小程序(书法识别)、PC(CRM、OA、ERP)",
        },
        {
          image: "/img/shenzhenranqi.png",
          Introduction:
            "深圳燃气简介深圳市燃气集团股份有限公司（简称深圳燃气），是一家以城市燃气经营为主的国有大型专业公司，创立于1982年，2004年改制为中外合资企业，2009年在上海证交所成功上市。公司总部所在的深圳市。目前，深圳燃气总资产超过120亿元人民币，年营业收入达到90亿元人民币。",
          title: "深圳燃气",
          subtitle: "安全检查系统",
          active: true,
        },
        {
          image: "/img/HX.png",
          Introduction:
            "海信地产成立于1995年，拥有国家最高等级的房地产开发资质、国家最高等级的建筑设计资质，是山东省综合实力最强的房地产开发企业之一。",
          title: "海信地产",
          subtitle: "信息化管理系统、招投标系统、信息汇总系统、楼盘系统",
        },
        {
          image: "/img/NH.png",
          Introduction:
            "中国农业银行成立于1951年。提供各种公司银行和零售银行产品和服务，同时开展金融市场业务及资产管理业务，业务范围还涵盖投资银行、基金管理、金融租赁、人寿保险等领域。",
          title: "农业银行",
          subtitle: "农行掌上银行、代发代扣、工资单、票据贴现、商城资源管理",
        },
        // {
        //   image: "/img/ZS.png",
        //   Introduction:
        //     "招商银行1987年成立于深圳蛇口，是中国境内第一家完全由企业法人持股的股份制商业银行，也是国家从体制外推动银行业改革的第一家试点银行。",
        //   title: "招商银行",
        //   subtitle: "适配器项目、跨行转账、抵押、商业汇票",
        // },
        {
          image: "/img/GS.png",
          Introduction:
            "中国工商银行成立于1984年1月1日。中国工商银行的基本任务是依据国家的法律和法规，通过国内外开展融资活动筹集社会资金，加强信贷资金管理，支持企业生产和技术改造，为我国经济建设服务。",
          title: "工商银行",
          subtitle: "融e购、基金理财、生活缴费、房管家",
        },
        {
          image: "/img/BM.png",
          Introduction:
            "华晨宝马汽车有限公司成立于2003年5月，是宝马集团和华晨汽车集团控股有限公司共同设立的合资企业。华晨宝马在辽宁省沈阳市配有先进的生产基地，已连续14年成为沈阳最大纳税企业。",
          title: "华晨宝马",
          subtitle: "保修索赔系统",
        },
        {
          image: "/img/FT.png",
          Introduction:
            "一汽丰田汽车有限公司成立于2000年6月，占地面积161万㎡，目前生产能力为年产42万辆，公司注册资本为33亿元人民币，一汽丰田已成为中国乘用车企业的中坚力量，用实际行动持续为日新月异发展的新时代做出贡献。",
          title: "一汽丰田",
          subtitle: "经销店销售业务支援系统、零件管理系统。",
        },
      ],
      active: 0,
      sele: true,
      x: 0,
    };
  },
  methods: {
    hoveritem(i) {
      this.active = i;
      this.x = i;
    },
    swipeClick() {
      this.$router.push("/about");
    },
    closeIcon() {
      this.windowShow = !this.windowShow;
    },
    showWindow() {
      this.windowShow = false;
    },
    selectStyle(i) {
      this.show[i] = true;
      console.log(this.show[i]);
    },
    outStyle(i) {
      this.show[i] = false;
      console.log(this.show[i]);
    },
    handleScroll() {
      //改变元素#searchBar的top值
      var scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= 900) {
        this.showIcon = true;
      } else {
        this.showIcon = false;
      }
      //测试 滚轮滑动
      // console.log(scrollTop);
    },
    //回到顶部
    goon() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    scrollTo(0, 0);
    //为window 添加监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    //离开该页面移除监听滚动事件
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.index {
  overflow: hidden;
}

.window {
  background-image: url("/img/fixedwindow.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 266px;
  position: fixed;
  right: -7px;
  bottom: 12.5rem;
  z-index: 20;
}
.window > .content {
  color: white;
  display: flex;
  flex-direction: column;
}
.window > .content > .top {
  margin-top: -10px;
  display: flex;
  align-items: center;
}
.window > .content > .top > img {
  width: 55px;
}
.window > .content > h2:nth-child(2) {
  font-size: 10px;
  margin-top: 5px;
  letter-spacing: 1.5px;
}
.window > .content > div:nth-child(3) {
  height: 10px;
  width: 65%;
  text-decoration: underline;
  border-bottom: 1px solid white;
}
.window > .content > h2:nth-child(4) {
  margin: 10px 0;
  font-size: 14px;
}
.window > .content > img {
  width: 70px;
  margin: 10px 0 5px 0;
}
.window > .content > h3 {
  font-size: 12px;
}
.window > img {
  margin: 13px 5px 0px 120px;
}

.window > img:hover {
  cursor: pointer;
}
.advantage {
  width: 1200px;
  animation: bounceInUp 5s;
}
.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content > .item {
  width: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content > .item > h1 {
  margin: 27px 0;
  font-size: 22px;
}
.icon {
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 20;
}
.icon > div {
  width: 60px;
  height: 60px;
  border: 1px solid #80808054;
  border-radius: 10px;
  margin-bottom: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.icon > div:hover {
  background-color: #1fa8f0;
  color: #f7f7f7;
  cursor: pointer;
}
.icon > div > p {
  font-size: 10px;
  margin-bottom: 12px;
}

.icon > .gotop:hover {
  color: #f7f7f7;
  background-image: url("/img/topwhite.png");
}
.icon > .gotop {
  background-image: url("/img/top.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 30%;
}
.icon > .gotop > p {
  margin-top: 42px;
}

.coreservice {
  margin: 40px -365px;
  display: flex;
  height: 374px;

  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
}
.coreservice > .content {
  margin-top: 24px;
  width: 1200px;
  display: flex;
  justify-content: space-around;
}
.coreservice > .content > .item {
  height: 250px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.coreservice > .content > .item:hover {
  border-bottom: 5px solid #22aaef;
  cursor: pointer;
}
.coreservice > .content > .item > .text > h1 {
  font-size: 18px;
  font-weight: 800;
  color: #000000;
  text-align: center;
  margin: 16px 0 8px;
}
.coreservice > .content > .item > .text > p {
  font-size: 16px;

  color: #676767;
  line-height: 26px;
  margin-top: 15px;
}
.service {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service > .content {
  margin-top: 60px;
  width: 1200px;
  display: flex;
  justify-content: space-around;
}
.service > .content > .item {
  width: 320px;
  height: 320px;
  border-radius: 25px;
  color: #ffffff;
  background-image: url("/img/xinbg.png");
  position: relative;
  transition: 0.5s;
}
@keyframes box-shadow-2 {
  from {
    box-shadow: inset 0 0 75px 20px slategray, 0 0 0 slategray;
  }
  to {
    box-shadow: inset 0 0 35px 20px transparent, 0 0 75px 20px transparent;
  }
}
.service > .content > .item:hover {
  animation: box-shadow-2 1s infinite alternate;
  box-shadow: inset 0 0 75px 50px slategray, 0 0 0 slategray;
  width: 320px;
  height: 320px;
  border-radius: 25px;
  color: #ffffff;
  background-image: url("/img/xinbg.png");
  position: relative;
  margin-top: -40px;
}

.service > .content > .item > img {
  position: absolute;
  top: -45px;
  left: 111px;
}

.service > .content > .item > ul {
  padding: 50px 40px;
}

.service > .content > .item > ul > li {
  line-height: 23px;
  padding-left: 15px;
  text-indent: -10px;
  width: 275px;
}
.service > .content > .item > ul > li > h1 {
  font-size: 24px;
  text-align: center;
  margin: 10px 0 30px 0;
}
.new {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new > .content {
  margin-top: 32px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.new > .content > .card {
  width: 320px;
  height: 400px;
  border-radius: 20px;
  border: 5px solid #dddddd59;
  box-shadow: 0 0 5px #dddddd59;
}
.new > .content > .card img {
  width: 320px;
  height: 250px;
  border-radius: 20px 20px 0 0;
}
.new > .content > .card > .text {
  padding: 5px 10px;
}
.new > .content > .card > .text > h1 {
  font-size: 18px;
  font-weight: 800;
  padding: 10px 0px 5px 2px;
  line-height: 24px;
  text-align: center;
}
.new > .content > .card > .text > p {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
}
.new > .content > .list {
  width: 840px;
  height: 400px;
}
.new > .content > .list > ul {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.new > .content > .list > ul > li {
  height: 50px;
  font-size: 18px;
  background-image: linear-gradient(
    to left,
    #e7c737 0%,
    #28b35b 50%,
    transparent 0%
  );
  position: relative;
  background-position: right bottom;
  background-size: 20px 1px;
  background-repeat: repeat-x;
  display: flex;
  align-items: center;
}

.new > .content > .list > ul > li > div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1fa8f0;
  margin: 0 15px 0 20px;
}
.new > .content > .list > ul > li > a:hover {
  color: #1fa8f0;
  cursor: pointer;
}
.new > .content > .list > ul > li > p {
  position: absolute;
  right: 10px;
  font-size: 18px;
}
.cooperation {
  margin: 40px -365px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cooperation > .subswipe {
  width: 1200px;
  height: 60px;
  background-color: transparent;
  margin: 20px auto;
  overflow: hidden;
  display: flex;
  position: relative;
}
.cooperation > .subswipe > .list {
  background-color: transparent;
  width: 200%;
  height: 60px;
  display: flex;
  animation: 15s move infinite linear;
}
@keyframes move {
  0% {
    transform: translate(0px);
  }
  100% {
    transform: translate(-1200px);
  }
}
.cooperation > .show {
  margin-top: 20px;
  width: 1200px;
  height: 480px;
  border-radius: 30px;
  display: flex;
  background-image: url("/img/hzhbBG.png");
}
.cooperation > .show > .left {
  z-index: 2;
  width: 240px;
  height: 480px;
}
.cooperation > .show > .left h1:hover {
  cursor: pointer;
}
.cooperation > .show > .right {
  z-index: 2;
  width: 800px;
  height: 480px;
  margin: 25px 0 0 80px;
  position: relative;
}
.cooperation > .show > .right > img {
  height: 60px;
  margin: 20px 0 30px 0;
}
.cooperation > .show > .right > p {
  font-size: 20px;
  color: #f2f2f2;
  line-height: 32px;
}

.cooperation > .show > .right > .line {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
}
.cooperation > .show > .right > div {
  position: absolute;
  top: 60%;
  left: 0;
  display: flex;
  flex-direction: column;
}
.cooperation > .show > .right > div > h1 {
  color: #ffffff;
  font-size: 26px;
}
.cooperation > .show > .right > div > div {
  position: absolute;
  top: 38px;
  height: 4px;
  width: 105px;
  border-radius: 20px;
  background-color: #1fa8f0;
}
.cooperation > .show > .right > div > p {
  margin-top: 40px;
  font-size: 20px;
  color: #ffffff;
}
.cooperation > .show > .left > div {
  width: 240px;
  height: 60px;
  background-color: #ffffff7e;
  position: relative;
}
.sele {
  background-color: transparent !important;
}
.sele > div {
  width: 10px;
  border-radius: 10px;
  height: 28px;
  background-color: #1fa8f0;
  position: absolute;
  left: 18%;
  top: 33%;
}
.sele > h1 {
  color: #dfdfdf;
}
.cooperation > .show > .left > div > div {
  top: 26%;
  width: 8px;
}
.cooperation > .show > .left > div:hover > div {
  width: 8px;
  border-radius: 10px;
  height: 28px;
  background-color: #1fa8f0;
  position: absolute;
  left: 18%;
  top: 26%;
}
.cooperation > .show > .left > div:hover {
  background-color: transparent;
}
.cooperation > .show > .left > div:hover > h1 {
  color: #dfdfdf;
}
.cooperation > .show > .left > div > h1 {
  text-align: center;
  line-height: 60px;
  font-size: 22px;
}
.cooperation > .subswipe > .list > div {
  width: 150px;
  height: 60px;
}

.cooperation > .subswipe > .list > div > img {
  width: 135px;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #8080807a;
}
/* .cooperation > .card {
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cooperation > .card > .item {
  width: 260px;
  height: 300px;
  border-radius: 20px;
  margin-top: 40px;
  border: 5px solid #1fa8f0;
  position: relative;
  transition: 0.5s;
}
.cooperation > .card > .item:hover {
  background-color: #55cfe6;
  width: 270px;
  height: 310px;
  float: inherit;
  margin: 25px -5px -50px;
}
.cooperation > .card > .item:hover > img {
  width: 160px;
  height: 55px;
}
.cooperation > .card > .item:hover > div > h1 {
  color: #ffffff;
}
.cooperation > .card > .item:hover > div > h2 {
  color: #ffffff;
  margin: 5px 0 0 10px;
}
.cooperation > .card > .item:hover > p {
  margin: 40px 25px;
}
.cooperation > .card > .item > p {
  margin: 35px 20px;
  line-height: 25px;
  width: 215px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
/* -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
/* -webkit-line-clamp: 4; /** 显示的行数 **/
/* overflow: hidden; */
/* transition: 0.5s; */
/* } */
/* .cooperation > .card > .item > div {
  margin: 24px 20px;
}
.cooperation > .card > .item > div > h1 {
  text-align: center;
  color: #1fa8f0;
  margin-bottom: 8px;
  font-size: 24px;
}
.cooperation > .card > .item > div > h2 {
  letter-spacing: 3px;
  line-height: 20px;
  text-align: center;
  width: 215px;
  transition: 0.5s;
}
.cooperation > .card > .item > img {
  width: 150px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: 55px;
  box-shadow: 0 0 5px #8080807a;
  border-radius: 20px;
  transition: 0.5s;
} */
.advantage {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  margin: 40px auto;
}
.advantage > .content {
  margin-top: 32px;
  width: 1200px;
}

.advantage > .content > .icon {
  display: flex;
  width: 1200px;
  justify-content: space-between;
}
.advantage > .content > .icon > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.advantage > .content > .icon h2 {
  font-size: 24px;
  margin-top: 15px;
}
.advantage > .content > .card {
  margin-top: 24px;

  display: flex;
  justify-content: space-between;
}
.advantage > .content > .card > .item {
  height: 320px;
  width: 220px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 0px 5px #888888;
}
.advantage > .content > .card > .item:hover {
  background-color: #55cfe6;
}
.advantage > .content > .card > .item:hover > .content {
  color: #f7f7f7;
}

.advantage > .content > .card > .item > .content {
  margin: 10px 10px;
  position: absolute;
  color: #55cfe6;
  height: 300px;
  width: 200px;
  font-size: 24px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 3px solid white;
  box-sizing: border-box;
}
.advantage > .content > .item > img {
  height: 70px;
}
.advantage > .content > .card > .item > .content > p {
  margin-top: 24px;
  text-align: center;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.profile {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  position: relative;
  margin: 10px;
}
.title > h1 {
  font-size: 56px;
  color: #dfdfdf;
  font-weight: 700;
}
.title > h3 {
  color: #000000;
  padding: 5px;
  font-size: 24px;
  position: absolute;
  left: 28%;
  top: 10px;
  border-bottom: 5px solid #1fa8f0;
}
.profile > .swipe {
  width: 1000px;
  margin-top: 40px;
}
.profile > .swipe img {
  width: 480px;
  height: 307px;
  border-radius: 20px;
  overflow: hidden;
}
.profile > .swipe .swipe-item {
  width: 480px;
}
.profile > .swipe .swipe-item:hover .p {
  display: block;
}

.profile .p {
  position: absolute;
  color: #f7f7f7;
  font-size: 12px;
  text-indent: 2em;
  border-radius: 0px 0px 20px 20px;
  padding: 10px 30px 5px;
  background-color: #0000005c;
  left: 0;
  bottom: 0;
  width: 480px;
  height: 35%;
  line-height: 25px;
  display: none;
  box-sizing: border-box;
}
a:link {
  color: #000000;
}

a:visited {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #000000;
  text-decoration: none;
}

a:active {
  color: #000000;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .container {
    overflow: hidden;
  }
  .index {
    overflow: visible;
  }
  .content > .item > h1 {
    font-size: 1.25rem;
  }
}
</style>