<template>
  <div class="foot">
    <div class="content">
      <div class="top">
        <div class="top-left">
          <ul @click="clickLeft">
            <li>
              <h1>主要模块</h1>
            </li>
            <li id="itservice">IT服务</li>
            <li id="software">软件研发</li>
            <li id="ai">人工智能</li>
            <!-- <li id="education">ABCode IT教育平台</li> -->
            <li><a href="http://www.abcodeedu.com/" target="_blank">ABCode IT教育平台</a></li>
          </ul>
          <ul @click="clickRight">
            <li>
              <h1>关于我们</h1>
            </li>
            <li id="about">关于我们</li>
            <li id="Culture">企业文化</li>
            <li id="case">合作案例</li>
            <li id="Contact">联系我们</li>
          </ul>
          <ul>
            <li>
              <h1>联系我们</h1>
            </li>
            <li>电话：18310141089</li>
            <li>
              <a
                target="_blank"
                href="https://www.amap.com/search?id=B0HDA5YB66&city=370112&geoobj=116.034465%7C36.132581%7C118.80778%7C37.277942&query_type=IDQ&query=%E7%BA%A6%E7%A0%81%E7%A7%91%E6%8A%80&zoom=9.06"
                >地址：济南市历城区耀泽商业广场1518室</a
              >
            </li>
            <li>
              <a href="mailto:591566764@qq.com">邮箱：591566764@qq.com</a> 
            </li>
            <li>时间：周一至周五( 9:00--18:00 )</li>
          </ul>
        </div>
        <div class="top-right">

          <div class="QR">
            <div class="QR-img">
              <img src="/img/QR2.png" alt="" />
            </div>
            <p>加我微信</p>
          </div>
          
           <div class="QR">
            <div class="QR-img">
              <img
                src="/img/dmy.png"
                alt=""
                style="width: 80px; height: 80px"
              />
            </div>
            <p>多麦芽</p>
          </div>
        </div>
      </div>
      <div class="bottom">
        山东省互联网协会成员单位
        <p></p><p></p><p></p>
        <p>Copyright 2021 - 2024 &#169;约码科技 &nbsp;&nbsp; All Rights Reserved</p>
        <p></p>
        <div>
          <img src="../assets/img/beian.png" alt="" />
          <p>
            <a
              target="_blank"
              href=" http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001358"
            >
              鲁公网安备37011202001358号
            </a>
          </p>
          <p>
            <a target="_blank" href="https://beian.miit.gov.cn">
              鲁ICP备2021024593号-2
            </a>
          </p>
        </div>
        
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    clickLeft(e) {
      // console.log(e.target.id);
      if (e.target.id == "") {
      } else {
        this.$router.push(`/${e.target.id}`);
      }
    },
    clickRight(e) {
      if (e.target.id == "") {
      } else {
        this.$router.push(`/${e.target.id}`);
      }
    },
  },
};
</script>

<style scoped>
li a:hover {
  color: #ffffff;
}
.foot {
  width: 100%;
  height: 280px;
  margin-top: 80px;
  background-color: black;
  display: fixed;
  bottom: 0;
}
.foot > .content {
  width: 1200px;
  margin: 0 auto;
}
.foot > .content > .bottom {
  height: 65px;
  color: #ffffffa3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.foot > .content > .bottom > p {
  font-size: 12px;
}
.foot > .content > .bottom > div > img {
  width: 16px;
}
.foot > .content > .bottom > div {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.foot > .content > .bottom > div :nth-child(2):hover {
  color: #ffffff;
  cursor: pointer;
}
.foot > .content > .bottom > div :nth-child(3):hover {
  color: #ffffff;
  cursor: pointer;
}
.foot > .content > .bottom > div :nth-child(3) {
  text-indent: 1em;
}
.foot > .content > .top > .top-left {
  height: 200px;
  display: flex;
  color: #ffffff;
}
.foot > .content > .top {
  display: flex;
}
.foot > .content > .top > .top-left > ul {
  margin: 32px 160px 0 0;
}
.foot > .content > .top > .top-left > ul > li {
  margin-bottom: 16px;
  font-size: 14px;
  color: #dddddda6;
}
.foot > .content > .top > .top-left > ul > li:hover {
  color: #ffffff;
  cursor: pointer;
}
.foot > .content > .top > .top-left > ul > li > h1 {
  margin-bottom: 24px;
  font-size: 16px;
  color: #ffffff;
}
.foot > .content > .top > .top-right {
  display: flex;
}
.foot > .content > .top > .top-right > .QR {
  margin-right: 32px;
}
.foot > .content > .top > .top-right > .QR > .QR-img {
  margin-top: 32px;
  border-radius: 10px;

  text-align: center;
  line-height: 90px;
}
.foot > .content > .top > .top-right > .QR > .QR-img > img {
  width: 80px;
  height: 80px;
}
.foot > .content > .top > .top-right > .QR > p {
  color: #ffffff;
  margin: 10px 5px;
  text-align: center;
}
a:link {
  color: #dddddda6;
}

a:visited {
  color: #dddddda6;
  text-decoration: none;
}

a:hover {
  color: #dddddda6;
  text-decoration: none;
}

a:active {
  color: #dddddda6;
  text-decoration: none;
}
@media screen and (max-width: 700px) {
  .foot {
    width: 1200px;
  }
  .foot > .content > .top {
    display: flex;
    justify-content: space-around;
  }
}
</style>